<template>
  <div>
    <NavTabsSwiper
      :tabs-list="tabsConsignmentList"
      :is-loading="isLoadingConsignmentList"
    />

    <router-view />
  </div>
</template>

<script>
import NavTabsSwiper from '@/shared/ui/tabs/NavTabsSwiper.vue'
import { mapState } from 'vuex'

export default {
  components: {
    NavTabsSwiper
  },

  computed: {
    ...mapState('tabsOrderList', ['tabsConsignmentList']),

    ...mapState('consignment', ['isLoadingConsignmentList'])
  }
}
</script>
