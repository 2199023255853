<template>
  <TabsSwiper class="tabs-swiper--border mb-30" :space-between="40">
    <NavItem
      class="swiper-slide"
      :class="classNameNavItem"
      v-for="(tab, index) in tabsList"
      :key="index"
      :navItem="tab"
      :is-disabled="isLoading"
    />
  </TabsSwiper>
</template>

<script>
import NavItem from '@/components/ui/Nav/NavItem'
import TabsSwiper from '@/components/ui/TabsSwiper/TabsSwiper'

export default {
  name: 'NavTabsSwiper',
  components: {
    NavItem,
    TabsSwiper
  },
  props: {
    tabsList: {
      type: Array,
      required: true
    },
    classNameNavItem: {
      type: String,
      required: false,
      default: 'tab-item--main'
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
